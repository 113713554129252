/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Button, Dropdown, DropdownButton, Form} from "react-bootstrap";
import PropTypes from "prop-types";
import {UserContext} from "../../../context/user";
import {postNewDomain} from "../../../apis/updateData";
import {getZoneIdForDomain, getDomainFunctionsWarmedUp, checkDomainV3} from "../../../apis/mp-configs";
import {poll} from "../../../common/helpers"
import v3Client from "../../../apis/v3Client";
import {queries} from "@testing-library/react";

const AddDomain = ({
  data,
  withBrandName,
  addDomainTo,
  closeModal,
  createDomain,
  configureDomain,
  waitIndicator,
  previewDomain,
  isNew,
  //refreshData,
}) => {

  // Location token
  let {token} = useParams();

  // Encrypted token
  const {state: user} = React.useContext(UserContext);
  const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');

  // Working domain data
  const [workingDomain, setWorkingDomain] = useState("");
  const [brandName, setBrandName] = useState("");
  const [offerId, setOfferId] = useState("");
  const [urlPrefix, setUrlPrefix] = useState("");

  // API Response
  const [responseCode, setResponseCode] = useState(0);

  // API Call
  async function pollNewDomainZone(domainName) {
    try {
      await poll({
        fn: async () => getZoneIdForDomain(encryptedToken, domainName), // return value will be passed to validate
        validate: (domain) => !!domain?.data?.result?.zoneId,
        interval: 1000,
        maxAttempts: 10,
        backoffRate: 2,
        exponential: true,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async function pollNewDomainV3(uid) {
    try {
      await poll({
        fn: async () => checkDomainV3(uid), // return value will be passed to validate
        validate: (response) => response.data.is_dns_confirmed,
        interval: 1000,
        maxAttempts: 10,
        backoffRate: 2,
        exponential: true,
      });
      return true;
    } catch (error) {
      return false;
    }
  }


  // Process step
  const [thisStep, setThisStep] = useState("create");

  // Build offer dropdown list
  const SelectOfferOptions = () => {
    // Get offers with an exclusive domain already
    const brandData = [];
    (data.exDomains).filter(function (e) {
      if(e.url === addDomainTo){
        (e.domains).map(function (el) {
          brandData.push(el.offerId);
        });
      }
    });
    const Content = [];
    (data.offerPrefixes).map((domain, index) => (
      Content.push(
        <option
          key={index}
          value={domain.networkOfferId}
          data-url-prefix={domain.customDomainPrefix}
          disabled={(~brandData.indexOf(domain.networkOfferId))}>
          {domain.displayName}
        </option>
      )
    ));
    return (
      <Form.Control
        id="offer-select"
        name="offer-select"
        as="select"
        custom={true}
        onChange={onSelectOffer}>
        <option value="" data-url-prefix="">Select an Offer</option>
        {Content}
      </Form.Control>
    );
  }

  // Content display
  const DisplayHeader = (step) => {
    switch (step) {
      case "create":
        return (
          <div className="modal-title h4">Add Exclusive Domain for: {withBrandName}</div>
        );
      case "processing":
        return null;
      case "success":
        return (
          <div className="modal-title success h4"><i className="fa fa-check"></i>Exclusive Domain Created</div>
        );
      case "failure":
        return (
          <div className="modal-title error h4">
            <i className="fa fa-exclamation-triangle"></i>
            {DisplayError(responseCode)[0]}
          </div>
        );
    }
  }
  const DisplayContent = (step) => {
    switch(step){
      case "create":
        return (
          <Form>
            {SelectOfferOptions()}
            {workingDomain &&
              <div className="domain-display">
                Domain: <strong>{workingDomain}</strong>
              </div>
            }
          </Form>
        );
      case "processing":
        return null;
      case "success":
        return (
          <div className="description">
            <p>Success! <strong>{workingDomain}</strong> has been created.</p>
            <p>Now, let&apos;s configure a pixel&hellip;</p>
          </div>
        );
      case "failure":
        return (
          <div className="description">
            {DisplayError(responseCode)[1]}
          </div>
        );
    }
  }
  const DisplayFooter = (step) => {
    switch (step) {
      case "create":
        return (
          <>
            <Button
              variant="link"
              onClick={closeModal}
              className="hidden"
            >
              Cancel
            </Button>
            <Button
              variant="action"
              disabled={workingDomain ? false : true}
              onClick={onCreate}
            >
              Create Exclusive Domain
            </Button>
          </>
        );
      case "processing":
        return null;
      case "success":
        return (
          <Button
            variant="action"
            onClick={(e) => configureDomain("", offerId, false)}
          >
            Next
          </Button>
        );
      case "failure":
        return (
          <Button
            variant="action"
            onClick={onReset}
          >
            Back
          </Button>
        );
    }
  }
  const DisplayError = (code) => {
    console.error('Domain creation failure:', code);
    const Content = [[],[]];
    if(parseInt(code) === 422){ // registration issue
      Content[0].push(
        <>Domain Not Created</>
      );
      Content[1].push(
        <p>
          <strong>We were unable to register {workingDomain}.</strong> Please try again and if this error persists please contact your GiddyUp Account Manager.
        </p>
      );
    }else if(parseInt(code) >= 400 && parseInt(code) <= 499){ // network error
      Content[0].push(
        <>Domain Not Created</>
      );
      Content[1].push(
        <p>
          <strong>A network error has occurred.</strong> Please try again and if this error persists please contact your GiddyUp Account Manager.
        </p>
      );
    }else if(parseInt(code) >= 500){ // server error
      Content[0].push(
        <>Domain Not Created</>
      );
      Content[1].push(
        <p>
          <strong>A server error has occurred.</strong> Please try again and if this error persists please contact your GiddyUp Account Manager.
        </p>
      );
    }else if(parseInt(code) === 1){ // polling failed
      Content[0].push(
        <>Domain Setup Not Completed</>
      );
      Content[1].push(
        <p>
          <strong>The setup process has timed-out.</strong> Please try again later and if this error persists please contact your GiddyUp Account Manager.
        </p>
      );
    }else{ // generic error
      Content[0].push(
        <>Domain Not Created</>
      );
      Content[1].push(
        <p>
          <strong>An error has occurred.</strong> Please try again and if this error persists please contact your GiddyUp Account Manager.
        </p>
      );
    }
    return Content;
  }
  const isDomainV3 = async (offer_id) => {
    try {
      // First, check if the offer is v3
      const is_v3_response = await v3Client.get(`/everflow/offers/${offer_id}/`);
      if (is_v3_response.status === 200) {
        return is_v3_response.data.is_v3;
      }
    } catch (error) {
      console.error(error.message);
      const errorData = {};
      if (error.response) {
        errorData.error = error.response.status;
      } else {
        errorData.error = "Unknown error occurred.";
      }
      return errorData;
    }
  }

  // Actions
  const onSelectOffer = (e) => {
    if(e.target.value !== ""){
      setOfferId(e.target.value);
      const dataset = e.target.options[e.target.selectedIndex].dataset;
      isDomainV3(e.target.value).then(is_v3 => {
        // got final result
        if (is_v3) {
          // Remove trailing slashes from the prefix
          // The new platform will not be using hyphens, so we don't need to show them in the preview url
          let prefix = dataset.urlPrefix.endsWith("-") ? dataset.urlPrefix.slice(0, -1) : dataset.urlPrefix;
          setWorkingDomain(prefix + "." + addDomainTo)
          setUrlPrefix(prefix);
        } else {
          setWorkingDomain(dataset.urlPrefix + addDomainTo);
        }
      }).catch(err => {
        // Fallback to "prefix domain.com" if there's an error getting the v3 status.
        let prefix = dataset.urlPrefix.endsWith("-") ? dataset.urlPrefix.slice(0, -1) : dataset.urlPrefix;
        setWorkingDomain(prefix + " " + addDomainTo);
      });
    }else{
      setOfferId('');
      setWorkingDomain('');
    }
  }

  const onReset = () => {
    setWorkingDomain("");
    setResponseCode(0);
    setThisStep("create");
  }
  const onCreate = async () => {
    if (offerId === "") return;

     // compose payload
     // addDomainTo is the "core domain" being used.
     const payload = {
      brandName: withBrandName,
      offerId: offerId,
      domainPrefix: urlPrefix,
      exclusiveDomain: addDomainTo,
      isNew: isNew,
    };

    // register domain
    waitIndicator("Creating your domain.");
    await getDomainFunctionsWarmedUp(); // warm up
    const data = await postNewDomain(encryptedToken, offerId, payload);

    if (data && data.error) {
      // failed with response
      waitIndicator("");
      setResponseCode(data.error);
      setThisStep("failure");
    } else {
      if (data && data.success) {
        // success
        waitIndicator("");
        previewDomain(true);
        if (data.domain_uid) {
          // If domain_uid was returned in data, the domain is V3.
          await domainSetupV3(data.domain_uid);
        } else {
          await domainSetup();
        }
      } else {
        // failed without response
        waitIndicator("");
        setThisStep("failure");
      }
    }
  }

  const domainSetup = async () => {
    waitIndicator("Completing setup.");
    const pollSucceeded = await pollNewDomainZone(workingDomain);

    if (pollSucceeded) {
      waitIndicator("");
      setThisStep("success");
      return;
    }

     waitIndicator("");
     setResponseCode(1);
     setThisStep("failure");
  }

  const domainSetupV3 = async (uid) => {
    waitIndicator("Completing setup.");
    const pollSucceeded = await pollNewDomainV3(uid);

    if (pollSucceeded) {
      waitIndicator("");
      setThisStep("success");
      return;
    }

    waitIndicator("");
    setResponseCode(1);
    setThisStep("failure");
  }

  // Effects
  useEffect(() => {
    setOfferId("");
    setWorkingDomain("");
    setThisStep("create");
  }, []);
  useEffect(() => {
    createDomain(workingDomain);
  }, [workingDomain]);

  return (
    <>
      <div className="modal-header custom">
        {DisplayHeader(thisStep)}
      </div>
      <div className="content custom">
        {DisplayContent(thisStep)}
      </div>
      <div className="modal-footer custom">
        {DisplayFooter(thisStep)}
      </div>
    </>
  );
};

AddDomain.propTypes = {
  data: PropTypes.object,
  withBrandName: PropTypes.string,
  addDomainTo: PropTypes.string,
  closeModal: PropTypes.func,
  createDomain: PropTypes.func,
  configureDomain: PropTypes.func,
  waitIndicator: PropTypes.func,
  previewDomain: PropTypes.func,
  refreshData: PropTypes.func,
  isNew: PropTypes.bool,
};

export default AddDomain;
