/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import CopyLink from "./CopyLink";
import QrCode from "./QrCode";
import PropTypes from "prop-types";
import { Accordion, AccordionContext, useAccordionToggle, Button, Card, Form } from "react-bootstrap";
import marketingPartnerApi from "../../apis/mp-configs";
import useApi from "../../hooks/useApi";
import TestPurchase from "../TestPurchase";
import {UserContext} from "../../context/user";
import {useParams} from "react-router-dom";
import {MDBRadio} from "mdb-react-ui-kit";
import v3Client from "../../apis/v3Client";

const TrackingLinkPanel = ({data}) => {

  // Location token
  let { token } = useParams();

  // Encrypted token
  const {state: user} = React.useContext(UserContext);
  const encryptedToken = token || user.token || (new URLSearchParams(window.location.search)).get('efo_mp');
  const [advertorialDomains, setAdvertorialDomains] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await v3Client.get('everflow/advertorial-domains');
        const domainsData = response.data;
        setAdvertorialDomains(domainsData);
      } catch (error) {
        console.error('There was an error retrieving the advertorial domains:', error);
      }
    })();
  }, []);

  // Accordion handling
  // eslint-disable-next-line react/prop-types
  function CustomToggle({eventKey, callback}) {
    const currentEventKey = React.useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;
    return (
      <button
        type="button"
        className={isCurrentEventKey ? "toggle expanded" : "toggle collapsed"}
        onClick={decoratedOnClick}
      ></button>
    );
  }

  // States
  const [offerLink, setOfferLink] = useState('');
  const [originalOfferLink, setOriginalOfferLink] = useState('');
  const [directLinking, setDirectLinking] = useState(false);
  const [linkParams, setLinkParams] = useState({});
  const handleParams = (input) => {
    if((input.target.value).trim() !== ""){
      setLinkParams({...linkParams, [input.target.id]: input.target.value});
    }else{
      const newParams = {...linkParams};
      delete newParams[input.target.id];
      setLinkParams(newParams);
    }
  }
  let mpGetDirectLinkTimer = null;
  let mpGetRedirectLinkTimer = null;
  const buildLink = () => {
    if (offerId !== "") {
      if (!directLinking) {
        const url = new URL(offerLink);
        let containsAdvertorialDomain = doesLinkContainAdvertorialDomain(url);
        url.search = new URLSearchParams(linkParams);
        url.searchParams.delete('link-type');
        // decode { and } for Everflow (%7B,%7D)
        const link = (url.href).replace(/%7B/g, '{').replace(/%7D/g, '}');
        setOfferLink(link);
      } else if (directLinking) {
        if (linkType === "direct") {
          setOfferLink("");
          if (mpGetDirectLinkTimer) {
            clearTimeout(mpGetDirectLinkTimer);
          }
          mpGetDirectLinkTimer = setTimeout(() => {
            mpGetDirectLink(encryptedToken, offerId, {
              ...linkParams
            });
          }, 500);
        } else if (linkType === "redirect") {
          setOfferLink("");
          if (mpGetRedirectLinkTimer) {
            clearTimeout(mpGetRedirectLinkTimer);
          }
          mpGetRedirectLinkTimer = setTimeout(() => {
            mpGetRedirectLink(encryptedToken, offerId, {
              ...linkParams
            });
          }, 500);
        }
      }
    }
  }

  const restParams = () => {
    const newParams = {...linkParams};
    delete newParams.uid;
    delete newParams.altdomain;
    delete newParams.linkType;
    setLinkParams(newParams);
    setExDomain('');
    setLinkUrl('');
    setBaseUrl('');
    setLinkType('redirect');
  }

  // Api hooks
  const {
    loading: mpOffersUrlsLoading,
    data: mpOffersUrlsData,
    request: mpOffersUrls,
    error: mpOffersUrlsError,
  } = useApi(marketingPartnerApi.getMarketingPartnerOfferUrls);

  // Api proxy domain
  const {
    loading: mpDirectLinkLoading,
    data: mpDirectLinkData,
    request: mpGetDirectLink,
    error: mpDirectLinkingError,
  } = useApi(marketingPartnerApi.getMarketingPartnerDirectLink);

  // Api proxy domain
  const {
    loading: mpRedirectLinkLoading,
    data: mpRedirectLinkData,
    request: mpGetRedirectLink,
    error: mpRedirectLinkingError,
  } = useApi(marketingPartnerApi.getMarketingPartnerRedirectLink);

  // Set offer ID
  const [offerId, setOfferId] = useState('');
  const SelectOfferId = () => {
    const Content = [];
    const dataList = data.runOffers;
    dataList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
    (dataList).map((domain, index) => {
      if(domain.name !== ""){
        Content.push(
          <option
            key={index}
            value={domain.networkOfferId}
            data-offer={domain.offer}
            data-tracking-url={domain.trackingUrl}
            data-has-direct-linking={domain.hasDirectLinking}
          >
            ({domain.networkOfferId}) {domain.name}
          </option>
        )
      }
    });
    return (
      <Form.Control
        id="offer-id"
        name="offer-id"
        as="select"
        custom={true}
        onChange={(e) => selectedOfferId(e)}
      >
        <option
          value=""
          data-tracking-url=""
        >
          Select an Offer
        </option>
        {Content}
      </Form.Control>
    );
  }
  const selectedOfferId = (e) => {
    setOfferId(e.target.value);
    const dataset = e.target.options[e.target.selectedIndex].dataset;
    setOfferLink(dataset.trackingUrl);
    setOriginalOfferLink(dataset.trackingUrl);
    setDirectLinking(dataset.hasDirectLinking === "true");
    setLinkType("redirect");
  }

  // Set Exclusive Domain
  const [exDomain, setExDomain] = useState('');
  const SelectExDomain = () => {
    let disableControl = true;
    const Content = [];
    if (offerId !== "") {
      const dataList = data.exDomains;
      (dataList).map((offer) => {
        (offer.domains).map((domain, index) => {
          if (parseInt(offerId) === parseInt(domain.offerId)) {
            disableControl = false;
            Content.push(
              <option key={index} value={domain.exclusiveDomain}>
                {domain.exclusiveDomain}
              </option>
            )
          }
        });
      });
    }
    return (
      <Form.Control
        id="altdomain"
        name="altdomain"
        as="select"
        custom={true}
        disabled={disableControl}
        value={exDomain}
        onChange={(e) => {
          handleParams(e);
          setExDomain(e.target.value);
        }}
      >
        {disableControl || offerId === ""
          ? <option value="" selected={true}> </option>
          : <option value="" selected={true}>Select an Exclusive Domain</option>
        }
        {Content}
      </Form.Control>
    );
  }

  // Set Url Link
  const [linkUrl, setLinkUrl] = useState('');
  const [baseUrl, setBaseUrl] = useState('');
  const SelectLinkUrl = () => {
    let disableControl = true;
    const Content = [];
    if(offerId !== "" && Object.keys(mpOffersUrlsData).length) {
      const dataList = mpOffersUrlsData.result.urls;
      dataList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      (dataList).map((offer, index) => {
        disableControl = false;
        Content.push(
          <option
            key={index}
            value={offer.uid}
            data-base-url={offer.url}
          >
            {offer.name}
          </option>
        )
      });
    }
    return (
      <Form.Control
        id="uid"
        name="uid"
        as="select"
        custom={true}
        disabled={disableControl}
        value={linkUrl}
        onChange={(e) => {
          const dataset = e.target.options[e.target.selectedIndex].dataset;
          setBaseUrl(dataset.baseUrl);
          setLinkUrl(e.target.value);
          handleParams(e);
        }}
      >
        {disableControl || offerId === ""
          ? <option value=""> </option>
          : <option value="">Base URL</option>
        }
        {Content}
      </Form.Control>
    );
  }

  // Set link type
  const [linkType, setLinkType] = useState('redirect');
  const SelectLinkType = () => {
    return (
        <Form.Control
            id="link-type"
            name="link-type"
            as="select"
            custom={true}
            value={linkType}
            onChange={(e) => selectedLinkType(e)}
        >
          <option
              key="redirect"
              value="redirect"
          >
            Redirect
          </option>
          <option
              key="direct"
              value="direct"
              disabled={!directLinking}
          >
            Direct
          </option>
        </Form.Control>
    );
  }
  const selectedLinkType = (e) => {
    setLinkType(e.target.value);
    handleParams(e);
  }

  // Effects
  useEffect(() => {
    if(offerId !== ""){
      mpOffersUrls(encryptedToken, offerId, {page: 1, pageSize: 2000});
      buildLink();
    }
  }, [offerId]);

  useEffect(() => {
    buildLink();
  }, [linkParams]);


  useEffect(() => {
    restParams();
  }, [mpOffersUrlsData]);

  function getBaseDomain(hostname) {
    const parts = hostname.split('.');
    if (parts.length <= 2) {
      return hostname; // This is already a base domain
    }

    // Return the last two parts as the base domain
    return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
  }

  function doesLinkContainAdvertorialDomain(link) {
    const url = new URL(link);
    const baseDomain = getBaseDomain(url.hostname);
    let searchList = advertorialDomains.map(item => item.domain)
    return searchList.includes(baseDomain);
  }

  function getAdvertorialSubdomain(domain) {
    const baseDomain = getBaseDomain(domain.hostname);
    let advertorial = advertorialDomains.find(item => item.domain === baseDomain);
    if (advertorial) {
      return advertorial.branded_subdomain + ".";
    }
    return ""
  }

  useEffect(() => {
    if(mpDirectLinkData?.result?.url) {
      let offerLink = mpDirectLinkData.result.url;
      if(baseUrl){
        let oldLink = new URL(offerLink);
        let newLink = new URL(baseUrl);
        oldLink.searchParams.set('uid', linkParams.uid);
        newLink.searchParams.forEach((value, key) => {
          const re = new RegExp("^{(.*)}$");
          if(!re.test(value)){
            oldLink.searchParams.set(key, value);
          }
        })
        if(linkParams.altdomain){
          oldLink.searchParams.set('altdomain', linkParams.altdomain);
          if (!doesLinkContainAdvertorialDomain(newLink)) {
            newLink.hostname = linkParams.altdomain;
          } else {
            let subdomain = getAdvertorialSubdomain(newLink);
            newLink.hostname = subdomain + linkParams.altdomain;
          }
        }
        newLink.search = oldLink.search;
        offerLink = newLink.href;

      }else if(linkParams.altdomain){
        let newLink = new URL(offerLink);
        newLink.hostname = linkParams.altdomain;
        offerLink = newLink.href;
      }
      offerLink = offerLink.replace(/%7B/g, '{').replace(/%7D/g, '}');
      setOfferLink(offerLink);
    }
  }, [mpDirectLinkData]);

  useEffect(() => {
    if(mpRedirectLinkData?.result?.url) {
      let offerLink = mpRedirectLinkData.result.url;

      let oldLink = new URL(offerLink);
      let newLink = new URL(offerLink);
      newLink.searchParams.forEach((value, key) => {
        const re = new RegExp("^{(.*)}$");
        if(!re.test(value)){
          oldLink.searchParams.set(key, value);
        }
      })
      if(linkParams.altdomain){
        oldLink.searchParams.set('altdomain', linkParams.altdomain);
      }
      if (baseUrl) {
        oldLink.searchParams.set('uid', linkParams.uid);
      }
      newLink.search = oldLink.search;
      offerLink = newLink.href;
      offerLink = offerLink.replace(/%7B/g, '{').replace(/%7D/g, '}');
      setOfferLink(offerLink);
    }
  }, [mpRedirectLinkData]);

  return (
    <Accordion className="tracking-panel" defaultActiveKey="0">
      <Card>
        <Card.Header className="row">
          <div className="col-12 padding-right-only">
            <Accordion.Toggle
              className="accordion-toggle"
              as={Button}
              variant="link"
              eventKey="0"
            >
              Tracking Link
            </Accordion.Toggle>
            <CustomToggle eventKey="0"/>
          </div>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Form>
              <label>Offer</label>
              {SelectOfferId()}

              <label>Exclusive Domain</label>
              {SelectExDomain()}

              <label>URL</label>
              {SelectLinkUrl()}

              <label>Link Type</label>
              {SelectLinkType()}

              <label>Source ID</label>
              <Form.Control type="text" id="source_id" onChange={handleParams}/>

              <label>Sub 1</label>
              <Form.Control type="text" id="sub1" onChange={handleParams}/>

              <label>Sub 2</label>
              <Form.Control type="text" id="sub2" onChange={handleParams}/>

              <label>Sub 3</label>
              <Form.Control type="text" id="sub3" onChange={handleParams}/>

              <label>Sub 4</label>
              <Form.Control type="text" id="sub4" onChange={handleParams}/>

              <label>Sub 5</label>
              <Form.Control type="text" id="sub5" onChange={handleParams}/>
            </Form>


            <div className="link-display">
              {offerLink &&
                <div className="actions">
                  <QrCode className="qr-code" urlString={offerLink}/>
                  <CopyLink className="copy-link" urlString={offerLink}/>
                </div>
              }
              {(mpDirectLinkLoading || mpRedirectLinkLoading) && <div className="loading small"><div className="spinner" /></div>}
              <p className="link-url">
                {offerLink}
              </p>
            </div>

            <TestPurchase
              withURL={offerLink}
              withLabel="Test Purchase"
            />

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

TrackingLinkPanel.propTypes = {
  data: PropTypes.object,
};
export default TrackingLinkPanel;